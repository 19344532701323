@use '../utilities/functions/_svg-uri.scss';

// Styles

@mixin style-anchor($inverted: false) {
  cursor: pointer;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: from-font;
  transition: color var(--easing-change);

  > span {
    text-decoration: if($inverted, none, underline);
  }

  &:hover {
    text-decoration: none;

    > span {
      text-decoration: if($inverted, underline, none);
    }
  }
}

@mixin style-anchor-plain($inverted: false) {
  cursor: pointer;
  text-decoration: if($inverted, none, underline);
  text-decoration-skip-ink: auto;
  text-decoration-thickness: from-font;
  transition: color var(--easing-change);

  &:hover {
    text-decoration: if($inverted, underline, none);
  }
}

@mixin style-list-base {
  list-style: none;
  padding: 0;

  li {
    margin-left: .1em;
    padding-left: 1em;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      height: 1em;
      margin-left: -1em;
      margin-right: .5em;
      vertical-align: baseline;
      width: .5em;
    }
  }
}

@mixin style-list-unordered {
  li {
    &::before {
      content: '•';
    }
  }
}

@mixin style-list-ordered {
  counter-reset: list-counter;

  li {
    counter-increment: list-counter;

    &::before {
      content: counter(list-counter); //'.';
    }
  }
}

@mixin style-theme-colors($postfix: '') {
  &[data-color='green'] {
    --color-background#{$postfix}: var(--color-background-green);
    --color-foreground#{$postfix}: var(--color-foreground-green);
  }

  &[data-color='orange'] {
    --color-background#{$postfix}: var(--color-background-orange);
    --color-foreground#{$postfix}: var(--color-foreground-orange);
  }

  &[data-color='blue'] {
    --color-background#{$postfix}: var(--color-background-blue);
    --color-foreground#{$postfix}: var(--color-foreground-blue);
  }

  &[data-color='pink'] {
    --color-background#{$postfix}: var(--color-background-pink);
    --color-foreground#{$postfix}: var(--color-foreground-pink);
  }

  &[data-color='yellow'] {
    --color-background#{$postfix}: var(--color-background-yellow);
    --color-foreground#{$postfix}: var(--color-foreground-yellow);
  }
}
