
*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-top: 0;
}

p {
  margin-bottom: 0;
  margin-top: 0;

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: from-font;

    &:not(:disabled) {
      cursor: pointer;
    }
  }
}

svg {
  display: inline-block;
  fill: currentcolor;
  height: 100%;
  stroke: none;
  vertical-align: top;
  width: 100%;
}

img,
iframe,
video,
object {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%;
}

button,
input,
textarea,
select {
  appearance: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
  min-height: 1em;
}

input[type='number'] {
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
}

input[type='search'] {
  appearance: textfield;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none;
    display: none;
  }
}

textarea {
  max-height: 50vh;
  min-height: 15rem;
  resize: vertical;
}

select {
  &:not(:disabled) {
    cursor: pointer;
  }
}

option {
  background: unset;
  color: unset;
}

button,
input[type='button'],
input[type='submit'] {
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font: inherit;
  padding: 0;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  &:not(:disabled) {
    cursor: pointer;
  }
}

picture {
  display: block;
  margin-left: 0;
  margin-right: 0;

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

label {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

iframe {
  border-width: 0;
  overflow: hidden;
}

fieldset {
  border-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  margin: 0;
  padding: 0;
}

summary {
  list-style: none;

  &::marker {
    display: none;
  }
}
