
.location-list {
  margin: var(--block-margin) 0;
  position: relative;

  .location-list__grid {
    @include grid-container;
  }

  .location-list__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .location-list__locations {
    padding-left: 3rem;
  }

  .location-list__location {
    --color-background-ball: var(--color-background);
    --color-foreground-ball: var(--color-foreground);

    @include style-theme-colors($postfix: '-ball');

    margin: 2rem 0;
    position: relative;

    &::before {
      background-color: var(--color-background-ball);
      border-radius: 50%;
      color: var(--color-foreground-ball);
      content: '';
      display: block;
      height: 1.5rem;
      left: -3rem;
      position: absolute;
      top: .2rem;
      width: 1.5rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .location-list__location-title {
    @include typography-heading-2;
  }

  .description-list__disclosure-content {
    .description-list {
      padding-left: 2rem;

      &::before {
        background-color: var(--color-background-ball);
        border-radius: 50%;
        content: '';
        display: block;
        height: 1rem;
        left: 0;
        position: absolute;
        top: .15rem;
        width: 1rem;
      }
    }
  }

  @include landscape {
    .location-list__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }

    .location-list__location {
      &::before {
        top: .4rem;
      }
    }
  }
}
