
:root {
  @include font-campton;

  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  font-weight: var(--base-font-weight);
  letter-spacing: var(--base-letter-spacing);
  line-height: var(--base-line-height);
}

html,
body {
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-rendering: optimizelegibility;
  width: 100%;
}

html {
  --color-background-yellow: var(--color-campaign-yellow);
  --color-foreground-yellow: var(--color-white);
  --color-background-orange: var(--color-campaign-orange);
  --color-foreground-orange: var(--color-white);
  --color-background-green: var(--color-campaign-green);
  --color-foreground-green: var(--color-white);
  --color-background-blue: var(--color-campaign-blue);
  --color-foreground-blue: var(--color-white);
  --color-background-pink: var(--color-campaign-pink);
  --color-foreground-pink: var(--color-white);

  // This notation allows for separate *-prevent-scrolling classnames for different components that shouldn't cancel each other
  &[class*='prevent-scrolling'] {
    max-height: 100vh;
    overflow: hidden;
  }

  &:not(.has-loaded) {
    &,
    * {
      // Disable all transitions on load
      transition: none !important;
    }
  }

  &.document--high-contrast {
    --color-foreground-yellow: var(--color-black);
    --color-foreground-orange: var(--color-black);
    --color-foreground-green: var(--color-black);
    --color-foreground-blue: var(--color-black);
    --color-foreground-pink: var(--color-black);
  }

  &.document--regular {
    --color-background: var(--color-grey-light);
    --color-foreground: var(--color-black);
  }

  &.document--overview {
    --color-background: var(--color-grey-light);
    --color-foreground: var(--color-black);
  }

  &.document--answer {
    @include style-theme-colors;
  }

  background-color: var(--color-background);
  color: var(--color-foreground);
  transition: color var(--easing-change), background-color var(--easing-change);
}

body {
  background-color: inherit;
  color: inherit;
  font: inherit;
  margin: 0 auto;
  max-width: var(--document-max-width);
  min-width: var(--document-min-width);
}
