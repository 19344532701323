
.figure {
  position: relative;

  .picture {
    width: 100%;
  }
}

.figure--regular {
  //
}

.figure--circular {
  .picture__image {
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    overflow: hidden;
  }
}
