
@media print {
  // Containers
  .container--header,
  .container--footer,
  .container--overlays {
    display: none;
  }

  // Structures (mostly covered by the containers, but still)
  .cookie-bar,
  .footer-bar,
  .menu-bar,
  .menu-overlay,
  .notification-bar,
  .skip-to-main {
    display: none;
  }

  // Media
  iframe,
  video {
    display: none;
  }

  img {
    break-inside: avoid;
    opacity: 1 !important;
    page-break-inside: avoid;
  }

  // Show FAQ items
  .disclosure__content-container {
    display: block !important;
  }
}
