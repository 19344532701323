
.person-header {
  margin-bottom: var(--block-margin);
  padding-top: calc(var(--block-margin) * 2);
  position: relative;

  .person-header__grid {
    @include grid-container;
  }

  .person-header__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    position: relative;
    text-align: center;
  }

  .person-header__title {
    @include typography-heading-1;
  }

  .person-header__introduction {
    margin: 1.5rem 0 1rem;
  }

  .person-header__picture {
    margin: 2.5rem 0;

    picture {
      aspect-ratio: 1/1;
      margin: 0 auto;
      max-height: 18rem;
    }
  }

  @include landscape {
    .person-header__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }
  }
}
