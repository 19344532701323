
.card {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color var(--easing-change), background-color var(--easing-change);
  user-select: none;
  width: 100%;

  .card__image {
    position: relative;

    img {
      aspect-ratio: 1/1;
    }
  }

  .card__content {
    background-color: var(--color-white);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-height: 16rem;
    padding: 1rem 1rem 3.5rem;
    position: relative;
    transition: background-color var(--easing-change), color var(--easing-change);
  }

  .card__title {
    @include typography-heading-3;

    margin-bottom: .3rem;
    word-break: break-word;
  }

  .card__date {
    @include typography-paragraph;

    margin-bottom: 1rem;
  }

  .card__text {
    @include typography-paragraph;

    flex: 1 1 auto;
  }

  .card__subtitle {
    @include typography-paragraph;

    bottom: .75rem;
    left: 1rem;
    position: absolute;
    width: calc(100% - 5rem);
  }

  .card__button {
    bottom: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &:hover,
  &:focus-visible {
    .card__button {
      background-color: var(--color-white);
      border-color: var(--color-white);
      color: var(--color-black);
    }
  }

  &:focus-visible {
    outline: 2px solid var(--color-black);
    outline-offset: 2px;
  }
}
