
.button {
  @include typography-button;

  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  line-height: 1em;
  max-width: 100%;
  min-width: calc(3em + 2px);
  overflow: hidden;
  padding: .75em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: color var(--easing-change), background-color var(--easing-change), border-color var(--easing-change), box-shadow var(--easing-change);
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  &:not(:disabled):focus-visible {
    outline: 2px solid var(--color-black);
    outline-offset: 2px;
  }

  .button__span {
    display: block;
    flex: 1 1 100%;
    height: 1em;
    // overflow: hidden; // Disabled because g j etc get cut off
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button__icon {
    display: block;
    flex: 0 0 1.375em;
    height: 1.375em;
    margin: -.1875em;
    width: 1.375em;
  }

  .button__icon + .button__span,
  .button__span + .button__icon {
    margin-left: .75em;
  }
}

.button:disabled,
.button--disabled,
.button[disabled='disabled'] {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.button--square {
  min-width: calc(1.5em + 2px);
  padding: .75em;

  .button__span {
    height: 1em;
    margin-left: -.75em;
    margin-right: -.75em;
    max-width: calc(2.5em);
    text-align: center;
    width: calc(2.1em);
  }
}

.button--icon-only {
  min-width: calc(2.5em + 2px);
  padding: .75em;

  .button__span {
    display: none;
  }
}

.button--regular {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-black);

  &:hover:not(.button--disabled, :disabled),
  &:focus-visible:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: var(--color-black);
    border-color: var(--color-black);
    color: var(--color-white);
  }
}

.button--primary {
  background-color: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);

  &:hover:not(.button--disabled, :disabled),
  &:focus-visible:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-black);
  }
}

.button--clean {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-foreground);

  .button__span {
    text-decoration: none;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: from-font;
  }

  &:hover:not(.button--disabled, :disabled),
  &:focus-visible:not(.button--disabled, :disabled),
  &.button--selected {
    .button__span {
      text-decoration: underline;
    }
  }
}

.button--outline {
  background-color: transparent;
  border-color: var(--color-black);
  border-width: 2px;
  color: var(--color-foreground);

  &:hover:not(.button--disabled, :disabled),
  &:focus-visible:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: var(--color-black);
    border-color: var(--color-black);
    color: var(--color-white);
  }
}

.button--inverted {
  background-color: var(--color-foreground);
  border-color: var(--color-foreground);
  color: var(--color-background);

  &:hover:not(.button--disabled, :disabled),
  &:focus-visible:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: var(--color-backgrond);
    border-color: var(--color-foreground);
    color: var(--color-foreground);
  }
}
