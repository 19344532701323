
.question-form-flow {
  inset: 0;
  min-height: 100vmin;
  position: fixed;
  z-index: var(--z-index-question-form-flow);

  &:not(.question-form-flow--active) {
    display: none;
  }

  &:not(.question-form-flow--at-form-section) .question-form-flow__form-section {
    display: none;
  }

  &:not(.question-form-flow--at-check-section) .question-form-flow__check-section {
    display: none;
  }

  &:not(.question-form-flow--at-done-section) .question-form-flow__done-section {
    display: none;
  }

  .question-form-flow__form-section,
  .question-form-flow__check-section,
  .question-form-flow__done-section {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .question-form-flow__check-section {
    --color-background: var(--color-grey-light);
    --color-foreground: var(--color-black);

    background-color: var(--color-grey-light);
    color: var(--color-black);
  }

  .question-form-flow__done-section {
    @include style-theme-colors;

    background-color: var(--color-background);
    color: var(--color-foreground);
  }

  .question-form-flow__richtext {
    margin: auto;
    max-width: 23rem;
    text-align: center;
  }

  //.option-field {
  //  .option-field__wrapper {
  //    display: flex;
  //  }
  //
  //  .option-field__item {
  //    margin: 0 auto;
  //  }
  //}
}
