
.homepage-balls {
  --grid-columns: 16;

  background-color: var(--color-grey-light);
  //height: calc(100vh - (2 * 2.75rem)); // Menu-bar + footer
  margin-bottom: 0 !important;
  //overflow-y: hidden;
  //position: absolute;
  //top: 0;
  //width: 100vw;

  &.show-grid {
    //height: auto;
    //min-height: 100vh;
    //position: relative;

    .homepage-balls__toggle-container {
      background-color: var(--color-grey-light);
    }

    .homepage-balls__partner-logo {
      display: none;
    }

    .homepage-balls__canvas-container {
      opacity: 0;
      pointer-events: none;
    }

    .homepage-balls__overview {
      display: block;
    }
  }

  .homepage-balls__toggle-container {
    @include grid-container;

    background-color: transparent;
    height: 0;
    left: 0;
    overflow: visible;
    position: fixed;
    right: 0;
    top: 2.75rem;
    z-index: var(--z-index-homepage-balls-toggle-container);

    .toggle {
      height: 2.75rem;
    }
  }

  .homepage-balls__toggle {
    grid-column: column 1 / span 16;
    justify-self: center;
    padding: .5rem 1.5rem;
  }

  .homepage-balls__partner-logo {
    bottom: .5rem;
    color: var(--color-grey);
    pointer-events: none;
    position: absolute;
    right: 1rem;
    text-align: center;
    width: 7rem;
    z-index: var(--z-index-homepage-balls-partner-logo);

    > svg {
      display: inline-block;
      margin: .5rem 1rem;
      position: relative;
      vertical-align: top;
      width: calc(100% - 2rem);
    }
  }

  .homepage-balls__partner-logo-title {
    @include typography-sponsor-logo;

    color: var(--color-grey-dark);
    display: block;
    font-weight: bold;
    text-align: center;
  }

  .homepage-balls__canvas-container {
    background-color: var(--color-grey-light);
    height: calc(100% - (2 * 2.75rem)); // Menu-bar + footer
    left: 0;
    position: fixed;
    top: 2.75rem;
    transition: opacity var(--easing-change);
    width: 100%;
    z-index: var(--z-index-homepage-balls-canvas-container);

    canvas {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .homepage-balls__overview {
    background-color: var(--color-grey-light);
    display: none;
    margin-bottom: 0;
  }

  .homepage-balls__filters-container {
    min-height: 8.75rem;
    padding-top: 4rem;
  }

  .homepage-balls__filters-grid {
    @include grid-container;
  }

  .homepage-balls__results-container {
    padding: 2.5rem .5rem;
  }

  .homepage-balls__results-grid {
    @include grid-container;
  }

  .homepage-balls__filters-mobile {
    grid-column: column 3 / span 12;
    text-align: center;

    .button {
      margin-top: 1rem;
    }
  }

  .homepage-balls__filters-landscape {
    display: none;

    .button {
      padding: .5em .75em;
      text-align: center;
    }
  }

  .homepage-balls__filter-button {
    &:hover:not(.button--disabled, :disabled),
    &:focus-visible:not(.button--disabled, :disabled),
    &.button--selected {
      background-color: var(--color-black);
      color: var(--color-white);

      .button__span {
        text-decoration: none;
      }
    }
  }

  .homepage-balls__zoom-container {
    bottom: 4rem;
    display: flex;
    position: fixed;
    right: 1.5rem;
    z-index: var(--z-index-homepage-balls-zoom-container);
  }

  @include landscape {
    --grid-columns: 32;

    height: 100vmin;
    top: 0;

    .homepage-balls__canvas-container {
      height: 100vmin;
      top: 0;
    }

    .homepage-balls__toggle-container {
      top: 0;
    }

    .homepage-balls__toggle {
      grid-column: column 1 / span 32;
    }

    .homepage-balls__results-container {
      margin-top: 3.75rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .homepage-balls__filters-mobile {
      display: none;
    }

    .homepage-balls__filters-landscape {
      display: flex;
      flex-flow: row wrap;
      gap: .25rem;
      grid-column: column 9 / span 16;
      justify-content: center;
    }

    .homepage-balls__zoom-container {
      bottom: 1.5rem;
      right: 1.5rem;
    }

    .homepage-balls__partner-logo {
      width: 7.75rem;

      > svg {
        width: 5.75rem;
      }
    }
  }
}
