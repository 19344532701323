
.answer-photo {
  margin: calc(var(--block-margin) * 1.5) 0;
  position: relative;

  .answer-photo__grid {
    @include grid-container;
  }

  .answer-photo__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  @include landscape {
    .answer-photo__wrapper {
      grid-column: column 10 / span calc(var(--grid-columns) - 18);
    }
  }
}
