
.container {
  position: relative;

  ::selection {
    background-color: var(--color-foreground);
    color: var(--color-background);
    text-shadow: none;
  }

  // hHide content from screens but not screenreaders
  @media screen {
    [hidden~='screen'] {
      display: inherit;
    }

    [hidden~='screen']:not(:active, :focus, :target) {
      clip: rect(0 0 0 0) !important;
      position: absolute !important;
    }
  }
}

.container--wrapper {
  //overflow-x: hidden; NOTE: Turned off since this causes double scrollbars -MAS
}

.container--header {
  z-index: 4;
}

.container--content {
  overflow-x: hidden;
  z-index: 1;

  > *:last-child {
    margin-bottom: var(--block-margin);
  }

  @include landscape {
    min-height: 100vmin;
  }
}

.container--footer {
  z-index: 2;
}

.container--overlays {
  z-index: 4;
}
