
.menu-overlay {
  --color-background: var(--color-black);
  --color-foreground: var(--color-white);

  background-color: var(--color-background);
  color: var(--color-foreground);
  display: none;
  flex-direction: column;
  inset: 0;
  min-height: 100%;
  position: fixed;
  transition: color var(--easing-change), background-color var(--easing-change);
  z-index: var(--z-index-menu-overlay);

  html.has-js & {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--easing-change);
  }

  html.has-js.menu-overlay-visible & {
    opacity: 1;
    pointer-events: auto;
  }

  html:not(.has-js).menu-overlay-visible & {
    display: flex;
  }

  .menu-overlay__scroll-container {
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    margin-bottom: 4.5rem;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
  }

  .menu-overlay__scroll-object {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-bottom: 2rem;
  }

  .menu-overlay__grid {
    @include grid-container;
  }

  .menu-overlay__links {
    @include typography-heading-2;

    display: flex;
    flex-direction: column;
    grid-column: column 3 / span calc(var(--grid-columns) - 4);
    margin: 1rem 0;
  }

  .menu-overlay__links-item {
    display: inline-block;
    margin: 1rem 0;
    vertical-align: baseline;
  }

  .menu-overlay__sublinks {
    @include typography-heading-6;

    display: flex;
    flex-direction: column;
    margin: .5rem 0 .25rem;
  }

  .menu-overlay__sublinks-item {
    display: inline-block;
    padding: .6rem 0;
    vertical-align: baseline;
  }

  .button--contrast {
    bottom: .75rem;
    position: fixed;
    right: .75rem;
  }

  @include landscape {
    left: auto;
    min-width: 400px;
    width: 30%;

    .logo {
      display: none;
    }
  }

  .menu-bar-contents__toggle-wrapper {
    display: none !important;
  }
}
