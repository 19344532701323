
.answer-video-embed {
  margin: calc(var(--block-margin) * 1.5) 0;
  position: relative;

  .answer-video-embed__grid {
    @include grid-container;
  }

  .answer-video-embed__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .video-embed {
    .video-embed__container,
    picture,
    .video-embed__iframe,
    iframe {
      aspect-ratio: 16 / 9;
      transition: aspect-ratio var(--easing-change);
    }

    &:not(.video-embed--loaded) {
      .video-embed__container,
      picture,
      .video-embed__iframe,
      iframe {
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  @include landscape {
    .answer-video-embed__wrapper {
      grid-column: column 10 / span calc(var(--grid-columns) - 18);
    }
  }
}
