@use 'sass:string';

.question-ball-grid {
  margin: 3rem 0;
  padding: 3rem 0;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .question-ball-grid__grid {
    @include grid-container;
  }

  .question-ball-grid__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .question-ball-grid__title {
    @include typography-heading-3;

    margin-bottom: 2rem;
  }

  .question-ball-grid__question-balls {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 1fr;

    .question-ball {
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  @include landscape {
    .question-ball-grid__wrapper {
      grid-column: column 2 / span calc(var(--grid-columns) - 2);
    }

    .question-ball-grid__question-balls {
      grid-template-columns: 1fr 1fr;
    }

    .question-ball-grid__title {
      margin-bottom: 2.5rem;
    }
  }

  @include notebook {
    .question-ball-grid__question-balls {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

.question-ball-grid--regular {
  //
}

.question-ball-grid--grey {
  --color-background: var(--color-grey-light);
  --color-foreground: var(--color-black);

  background-color: var(--color-background);
  color: var(--color-foreground);
}
