
.option-field {
  position: relative;

  .option-field__label {
    @include typography-field-label;

    cursor: pointer;
    display: block;
    line-height: 2rem;
    margin: 1rem 0;
    user-select: none;
  }

  .option-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .option-field__item {
    display: block;
  }

  .option-field__input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:not(:disabled):focus-visible {
      + .option-field__input-label {
        .option-field__input-label-span {
          outline: 2px solid currentcolor;
          outline-offset: 2px;
        }
      }
    }

    &:not(:disabled, :checked) {
      + .option-field__input-label {
        &:hover {
          .option-field__icon-background {
            background-color: var(--color-overlay-background);
          }
        }
      }
    }

    &:disabled {
      + .option-field__input-label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + .option-field__input-label {
        .option-field__icon-foreground {
          opacity: 1;
        }
      }
    }
  }

  .option-field__input-label {
    cursor: pointer;
    display: block;
    margin: 0;
    min-height: 1rem;
    padding: 0 0 0 1.5rem;
    position: relative;
    text-transform: none;
    user-select: none;
    width: 100%;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .option-field__input-label-span {
    @include typography-paragraph-large;

    display: inline-block;
    vertical-align: baseline;

    > a {
      &,
      &:link,
      &:active,
      &:visited {
        @include style-anchor-plain($inverted: false);

        color: inherit;
      }
    }
  }

  .option-field__icon {
    background: var(--color-background);
    color: var(--color-foreground);
    display: block;
    height: 1rem;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: .4rem;
    transition: opacity var(--easing-change) -faster, background-color var(--easing-change) -faster, color var(--easing-change) -faster;
    width: 1rem;
  }

  .option-field__icon-background {
    opacity: 1;
  }

  .option-field__icon-foreground {
    opacity: 0;
  }

  &.option-field--required {
    .option-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .15em;
      }
    }
  }

  &:not(.option-field--required) {
    .option-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .15em;
      }
    }
  }

  &.option-field--error {
    .option-field__error {
      @include typography-paragraph;

      color: var(--color-error);
      display: inline-block;
      max-width: 100%;
      width: auto;
    }

    .option-field__input {
      border-color: var(--color-error);
    }
  }

  &.option-field--inline {
    .option-field__item {
      display: inline-block;
      margin-right: 1rem;
      width: auto;
    }
  }

  &.option-field--radio {
    .option-field__icon-background,
    .option-field__icon-foreground {
      border-radius: 50%;
      overflow: hidden;
    }

    .option-field__icon-background {
      border: 1px solid currentcolor;
    }

    .option-field__icon-foreground {
      background: currentcolor;
    }
  }

  &.option-field--checkbox {
    .option-field__icon-background,
    .option-field__icon-foreground {
      border: 1px solid currentcolor;
    }
  }
}
