
.menu-bar {
  --color-background: var(--color-black);
  --color-foreground: var(--color-white);

  height: 2.75rem;
  pointer-events: none;
  position: relative;

  .menu-bar__contents-wrapper {
    background-color: var(--color-background);
    color: var(--color-foreground);
    height: 2.75rem;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  @include landscape {
    --color-background: transparent;
    --color-foreground: inherit;

    height: 0;
  }
}
