
.text-field {
  position: relative;

  .text-field__label {
    @include typography-field-label;

    cursor: pointer;
    display: block;
    line-height: 2rem;
    margin: 0;
    user-select: none;
  }

  .text-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .text-field__input {
    @include typography-field-input;

    background-color: var(--color-background);
    border-bottom: 2px solid var(--color-foreground);
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    color: var(--color-foreground);
    display: inline-block;
    flex: 1 1 100%;
    height: 2.5rem;
    line-height: 2rem;
    margin: 0;
    min-height: 2.5rem;
    min-width: 2.5rem;
    overflow: hidden;
    padding: .25rem .5rem;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:not(:disabled):focus-visible {
      outline: 2px solid currentcolor;
      outline-offset: 2px;
    }

    &:hover:not(:disabled) {
      //
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }

    &.text-field__input--has-suffix {
      padding-right: 2rem;
    }
  }

  .text-field__suffix {
    @include typography-field-input;

    bottom: 0;
    font-weight: bold;
    line-height: 2.5rem;
    position: absolute;
    right: .5rem;
    top: 0;
    width: auto;
  }

  &.text-field--required {
    .text-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.text-field--required) {
    .text-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.text-field--error {
    .text-field__label {
      color: var(--color-error);
    }

    .text-field__input {
      border-bottom-color: var(--color-error);
    }

    .text-field__error {
      @include typography-paragraph;

      color: var(--color-error);
      display: inline-block;
      max-width: 100%;
      width: auto;
    }
  }
}

.text-field--text {
  //
}

.text-field--number {
  //
}

.text-field--password {
  //
}

.text-field--email {
  //
}

.text-field--textarea {
  .text-field__input {
    max-height: 30rem;
    min-height: 9rem;
  }
}

.text-field--search {
  .text-field__input {
    padding-right: 3rem;
  }

  .text-field__button-icon {
    border: 1.75rem solid transparent;
    fill: currentcolor;
    height: 3rem;
    left: 0;
    line-height: 3rem;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 0;
    width: 3rem;
    z-index: 1;
  }

  .text-field__button {
    border-radius: 0;
    color: inherit;
    height: 3rem;
    margin: 0;
    margin-left: -3rem;
    padding: .5rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;

    .button__icon {
      height: 2rem;
      width: 2rem;
    }
  }
}

.text-field--phone {
  //
}
