
.answer-header {
  margin-bottom: var(--block-margin);
  padding-top: calc(var(--block-margin) * 2);
  position: relative;

  .answer-header__grid {
    @include grid-container;
  }

  .answer-header__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    position: relative;
    text-align: center;
  }

  .answer-header__title {
    @include typography-heading-1;
  }

  .answer-header__pretitle {
    @include typography-heading-5;

    margin-bottom: 1rem;
  }

  .answer-header__subtitle {
    @include typography-paragraph-large;

    margin-top: 1.5rem;
  }

  @include landscape {
    .answer-header__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }
  }
}
