
.dropdown-field {
  position: relative;

  .dropdown-field__label {
    @include typography-field-label;

    cursor: pointer;
    display: block;
    line-height: 2rem;
    margin: 1rem 0;
    user-select: none;
  }

  .dropdown-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .dropdown-field__input {
    @include typography-field-input;

    // background-color: var(--color-background);
    background-color: transparent;
    border-bottom: 2px solid var(--color-foreground);
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    color: var(--color-foreground);
    display: inline-block;
    flex: 1 1 100%;
    height: 3rem;
    line-height: 2rem;
    margin: 0;
    min-height: 3rem;
    min-width: 3rem;
    overflow: hidden;
    padding: .5rem 3rem .5rem 0;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:not(:disabled):focus-visible {
      outline: 2px solid currentcolor;
      outline-offset: 2px;
    }

    &:hover:not(:disabled) {
      //
    }

    + .dropdown-field__button {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: inherit;
      }
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  .dropdown-field__icon {
    border: .75rem solid transparent;
    fill: currentcolor;
    height: 3rem;
    line-height: 3rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 3rem;
    z-index: 1;
  }

  &.dropdown-field--required {
    .dropdown-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.dropdown-field--required) {
    .dropdown-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.dropdown-field--error {
    .dropdown-field__label {
      color: var(--color-error);
    }

    .dropdown-field__input {
      border-bottom-color: var(--color-error);
    }

    .dropdown-field__error {
      @include typography-paragraph;

      color: var(--color-error);
      display: inline-block;
      max-width: 100%;
      width: auto;
    }
  }
}
