@use '../../../assets/stylesheets/utilities/mixins/adaptive-margin';

.form-row {
  @include adaptive-margin.apply(1rem);

  margin-left: -.5rem;
  margin-right: -.5rem;

  > div {
    margin: 0 .5rem;
  }

  @include portrait {
    display: flex;

    > div {
      flex: 1 1 50%;
    }
  }
}

.form-row--regular {
  //
}

.form-row--footer {
  @include adaptive-margin.apply(calc(var(--block-margin) / 2), calc(var(--block-margin) / 2));

  margin-left: 0;
  margin-right: 0;

  .button + .button {
    margin-left: 10px;
  }

  @include mobile-only {
    .button {
      width: 100%;
    }

    .button + .button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
