
.article-header {
  margin-bottom: var(--block-margin);
  position: relative;

  .article-header__picture {
    position: relative;
    z-index: 0;

    picture {
      aspect-ratio: 16 / 9;
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .article-header__grid {
    @include grid-container;
  }

  .article-header__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    margin-top: 47vw;
    min-height: 4.5rem;
    position: relative;
    z-index: 1;

    &::before {
      --column-width: (100% / 12);

      background-color: var(--color-background);
      color: var(--color-foreground);
      content: '';
      display: block;
      height: calc(100% + 2rem);
      left: calc((var(--column-width) / 2) * -1);
      position: absolute;
      top: -2rem;
      width: calc(100% + (var(--column-width) * 2));
      z-index: -1;
    }
  }

  .article-header__title {
    @include typography-heading-1;
  }

  .article-header__introduction {
    margin: 1.5rem 0 0;
  }

  .description-list {
    margin: 1rem 0;
  }

  @include landscape {
    .article-header__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
      margin-top: 66vh;

      &::before {
        --column-width: (100% / 18);

        height: calc(100% + 5rem);
        left: calc((var(--column-width) * 2) * -1);
        top: -3.5rem;
        width: 100vw;
      }
    }

    .article-header__picture {
      .picture {
        height: 66vh;
      }
    }
  }
}
