
// Exposed variables
:root {
  // Base colors
  --color-black: #{#000f};
  --color-white: #{#ffff};

  // Theme colors
  --color-green: #{#00e63cff};

  // Greyscale colors
  --color-grey: #{#747474ff};
  --color-grey-light: #{#e8e8e8ff};
  --color-grey-dark: #{#474747};

  // Functional colors
  --color-error: #{#e00f}; // WCAG contrast requirements

  // Campaign colors
  --color-campaign-yellow: #{#ffcb00ff};
  --color-campaign-orange: #{#ff3c00ff};
  --color-campaign-green: #{#4db05bff};
  --color-campaign-blue: #{#0096faff};
  --color-campaign-pink: #{#f03ca5ff};

  // Transparent colors
  --color-overlay-background: #{#fffc};
  --color-overlay-foreground: #{#0000};

  // Beziers
  --bezier-fancy: #{cubic-bezier(.26, .88, .57, .9)};

  // Easings
  --easing-change: #{.3s ease-out};
  --easing-change-slower: #{.5s ease-out};
  --easing-change-faster: #{.15s ease-out};
  --easing-change-extraslow: #{1.5s ease-out};
  --easing-linear: #{.3s linear};

  // Layers / z-indexes
  --z-index-hide: #{-1};
  --z-index-hit-target: #{100};
  --z-index-form-field-button: #{10};
  --z-index-menu-overlay: #{2048};
  --z-index-question-flow-overlay: #{3000};
  --z-index-question-form-flow: #{3000};
  --z-index-notification-bar: #{4096};
  --z-index-homepage-balls-toggle-container: #{1050};
  --z-index-homepage-balls-partner-logo: #{1049};
  --z-index-homepage-balls-canvas-container: #{1048};
  --z-index-homepage-balls-zoom-container: #{1024};

  // Color inheritance base
  --color-background: var(--color-white);
  --color-foreground: var(--color-black);
}

@media print {
  //
}

@media (prefers-reduced-motion) {
  :root {
    --easing-change: .001s linear;
  }
}
