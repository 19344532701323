
.modal-content {
  margin: var(--block-margin) 0;
  position: relative;

  .modal-content__buttons {
    font-size: 0;
    margin-top: 2rem;
    text-align: center;

    .button {
      margin-right: .5rem;
    }
  }

  .richtext {
    text-align: center;
  }
}
