
.article-content {
  margin: var(--block-margin) 0;
  position: relative;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .article-content__buttons {
    font-size: 0;
    margin-bottom: -.5rem;
    margin-top: 2rem;

    .button {
      margin-bottom: .5rem;
      margin-right: .5rem;
    }
  }

  @include landscape {
    .article-content__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }
  }
}
