
.basic-header {
  margin-bottom: var(--block-margin);
  padding-top: calc(var(--block-margin) * 2);
  position: relative;

  .basic-header__grid {
    @include grid-container;
  }

  .basic-header__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    position: relative;
  }

  .basic-header__title {
    @include typography-heading-1;
  }

  .basic-header__introduction {
    margin: 1.5rem 0 1rem;
  }

  @include landscape {
    .basic-header__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }
  }
}
