
.question-flow-overlay {
  --color-background: var(--color-grey-light);
  --color-foreground: var(--color-black);

  background-color: var(--color-background);
  color: var(--color-foreground);
  display: none;
  inset: 0;
  min-height: 100vmin;
  position: fixed;
  transition: color var(--easing-change), background-color var(--easing-change);
  z-index: var(--z-index-question-flow-overlay);

  html.has-js & {
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--easing-change);
  }

  html.has-js.question-flow-overlay-visible & {
    opacity: 1;
    pointer-events: auto;

    .question-flow-overlay__starter:not(.question-flow-overlay__starter--active, .question-flow-overlay__starter--hidden) {
      pointer-events: initial;
    }
  }

  html:not(.has-js).question-flow-overlay-visible & {
    display: flex;
  }

  .question-flow-overlay__grid {
    @include grid-container;
  }

  .question-flow-overlay__wrapper {
    display: flex;
    flex-direction: column;
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    justify-content: center;
  }

  .question-flow-overlay__ball-outer {
    --color-background: var(--color-background);
    --color-foreground: var(--color-foreground);

    @include style-theme-colors;

    aspect-ratio: 1 / 1;
    background-color: var(--color-background);
    border-radius: 50%;
    color: var(--color-foreground);
    height: auto;
    left: 50vw;
    margin: auto;
    overflow: hidden;
    position: fixed;
    top: 50vh;
    transform: translate(-50%, -50%);
    transition: height var(--easing-change), width var(--easing-change);

    &:not(.question-flow-overlay__ball-outer--expanded) {
      cursor: pointer;
      transition: none;

      &:focus-visible {
        outline: 2px solid var(--color-black);
        outline-offset: 2px;
      }
    }

    &.question-flow-overlay__ball-outer--expanded {
      clip-path: circle(50%);

      .question-flow-overlay__scroll-container {
        overflow-y: scroll;
      }

      .question-flow-overlay__scroll-object {
        padding: 0 1rem;
      }
    }
  }

  .question-flow-overlay__ball-inner {
    inset: 0;
    padding: 0 15%;
    position: absolute;
  }

  .question-flow-overlay__scroll-container {
    height: 70%;
    inset: 15%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    width: 70%;
  }

  .question-flow-overlay__scroll-object {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  .question-flow-overlay__starters {
    aspect-ratio: 1 / 1;
    height: auto;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
  }

  .question-flow-overlay__starter {
    --multiplication-value: 1;
    --color-background: var(--color-background);
    --color-foreground: var(--color-foreground);

    @include style-theme-colors;

    height: 100%;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transform: none;
    transform-origin: 50% 50%;
    transition: opacity var(--easing-change);
    width: 100%;

    &.question-flow-overlay__starter--active,
    &.question-flow-overlay__starter--hidden {
      opacity: 0;
      pointer-events: none;
    }

    &:not(.question-flow-overlay__starter--hidden) {
      .question-flow-overlay__starter-button {
        &:focus-visible {
          outline: 2px solid var(--color-black);
          outline-offset: 2px;
        }
      }
    }

    &:nth-child(1) {
      transform: translate(calc(-180% * var(--multiplication-value)), calc(-220% * var(--multiplication-value)));
    }

    &:nth-child(2) {
      transform: translate(calc(-70% * var(--multiplication-value)), calc(-270% * var(--multiplication-value)));
    }

    &:nth-child(3) {
      transform: translate(calc(180% * var(--multiplication-value)), calc(-250% * var(--multiplication-value)));
    }

    &:nth-child(4) {
      transform: translate(calc(190% * var(--multiplication-value)), calc(230% * var(--multiplication-value)));
    }

    &:nth-child(5) {
      transform: translate(calc(-150% * var(--multiplication-value)), calc(250% * var(--multiplication-value)));
    }
  }

  .question-flow-overlay__starter-button {
    align-items: center;
    background-color: var(--color-background);
    border-radius: 50%;
    color: var(--color-foreground);
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    text-align: center;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform var(--easing-change);
    width: 100%;

    &:hover,
    &:focus-visible {
      transform: scale(1.15);
    }
  }

  .question-flow-overlay__form {
    position: relative;
    text-align: center;

    .text-field__wrapper,
    .option-field__wrapper {
      text-align: left;
    }

    .text-field.text-field--required {
      .text-field__label::after {
        display: none;
      }
    }

    .option-field.option-field--required {
      .option-field__label::after {
        display: none;
      }
    }
  }

  .question-flow-overlay__section {
    display: none;

    textarea {
      height: 7.25rem;
      resize: none;
    }

    input::placeholder {
      color: inherit;
      font-style: italic;
    }
  }

  .text-field {
    margin: 2rem auto 0;

    .text-field__label {
      @include typography-heading-4;

      margin-bottom: .5rem;
    }

    .text-field__input {
      background-color: transparent;

      &:disabled {
        opacity: 1;
      }
    }

    .text-field__wrapper,
    .text-field__label {
      margin-left: auto;
      margin-right: auto;
    }

    &.text-field--textarea {
      .text-field__wrapper,
      .text-field__label {
        max-width: min(30rem, 75vw);
      }
    }

    &.text-field--text,
    &.text-field--email {
      .text-field__wrapper,
      .text-field__label {
        max-width: min(30rem, 75vw);
      }
    }

    &.text-field--number {
      .text-field__wrapper,
      .text-field__label {
        max-width: min(15rem, 75vw);
      }
    }
  }

  .option-field {
    margin: 2rem auto 0;

    .option-field__label {
      @include typography-heading-4;

      margin-bottom: .5rem;
    }

    .option-field__wrapper {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .question-flow-overlay__section--preview {
    .text-field {
      cursor: pointer;
      margin-top: 0;
      pointer-events: none;
    }
  }

  .question-flow-overlay__section--active {
    display: block;
  }

  .button--close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  .button--info {
    bottom: 1.5rem;
    position: absolute;
    right: 1.5rem;
  }

  .button--next {
    margin-top: 2rem;
  }

  .question-flow-overlay__postview {
    margin: 0 auto;
    pointer-events: none;
    transform: scale(.9);
    transform-origin: top center;
  }

  .question-flow-overlay__postview-title {
    @include typography-heading-4;

    margin-bottom: .5rem;
  }

  .question-flow-overlay__postview-text {
    //
  }

  @include aspect-ratio-portrait {
    .question-flow-overlay__ball-outer {
      height: auto;
      width: 80vmin;

      &.question-flow-overlay__ball-outer--expanded {
        width: 80vmax;
      }
    }

    .question-flow-overlay__starters {
      width: 20vmin;
    }
  }

  @include aspect-ratio-landscape {
    .question-flow-overlay__ball-outer {
      height: 80vmin;
      width: auto;

      &.question-flow-overlay__ball-outer--expanded {
        height: 125vmin;
      }
    }

    .question-flow-overlay__starters {
      width: 10vmin;
    }

    .question-flow-overlay__starter {
      --multiplication-value: 1.65;
    }
  }

  @include landscape {
    .question-flow-overlay__wrapper {
      grid-column: column 5 / span calc(var(--grid-columns) - 8);
    }

    //.question-flow-overlay__section {
    //  margin-top: 2rem;
    //}
  }
}
