
.footer-bar {
  --color-background: var(--color-black);
  --color-foreground: var(--color-white);

  background-color: var(--color-background);
  color: var(--color-foreground);

  .footer-bar__grid {
    @include grid-container;
  }

  .footer-bar__wrapper {
    display: flex;
    flex-direction: column;
    grid-column: column 1 / span var(--grid-columns);
    padding: 0 1rem;
  }

  .footer-bar__follow {
    padding: .4rem 0;
  }

  .footer-bar__follow-title {
    display: inline-block;
    margin-right: 1rem;
    padding: .6rem 0;
    vertical-align: bottom;
  }

  .footer-bar__follow-buttons {
    display: inline-flex;
    margin: auto 0;

    .button--follow {
      vertical-align: middle;
    }
  }

  .footer-bar__links {
    align-items: center;
    display: inline-flex;
    padding: 1rem 0;
  }

  @include portrait {
    .footer-bar__wrapper {
      flex-direction: row;
    }

    .footer-bar__links {
      margin-left: auto;
    }
  }
}
