
.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
    width: 100%;

    .picture {
      opacity: 1;
    }
  }

  .video-embed__caption {
    @include typography-caption;
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__video {
    background-color: var(--color-background);
  }

  .video-embed__hit-target {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover,
    &:focus-visible {
      .button--play {
        background-color: var(--color-black);
        border-color: var(--color-black);
        color: var(--color-white);
      }
    }
  }

  .button--play {
    min-width: calc(1.5em + 2px);
    padding: .5em;
    pointer-events: none;
    transform: scale(1.5);
    transform-origin: 50% 50%;
  }

  .video-embed__iframe,
  iframe { // Vimeo doesn't append a class
    background-color: var(--color-background);
    color: var(--color-foreground);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity var(--easing-change) -slower;
    width: 100%;
  }

  &.video-embed--loaded {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--loaded) {
    .video-embed__video {
      filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      display: block;
    }
  }
}
