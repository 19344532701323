@use 'sass:string';

// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  // Breakpoints
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  --breakpoint-desktop: #{$desktop};

  // Breakpoint reference (for javascript)
  --breakpoint: var(--breakpoint-mobile);

  // Document constraints
  --document-min-width: #{$website-min-width};
  --document-max-width: #{$website-max-width};

  // Helper variables
  --full-grid-column-width: calc((100% - (var(--grid-margin) * 2) - (var(--grid-gutter) * (var(--grid-columns) + 1))) / var(--grid-columns));

  @include mobile {
    --grid-debug-color: #f06;
    --grid-columns: 14;
    --grid-gutter: 0px;
    --grid-margin: 0px;
    --grid-max-width: 100%;
    --grid-spacing: 0px;
    --grid-gap: 1rem;
    --inner-padding: .5rem;
    --block-margin: 1.5rem;
    --card-grid-columns: 2;
  }

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
    --grid-debug-color: #0cf;
    --card-grid-columns: 3;
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landcape);
    --grid-debug-color: #0f9;
    --grid-columns: 30;
    --block-margin: 3rem;
    --card-grid-columns: 4;
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
    --grid-debug-color: #c3f;
    --card-grid-columns: 5;
  }

  @include desktop {
    --breakpoint: var(--breakpoint-desktop);
    --grid-debug-color: #f06;
    --card-grid-columns: 6;
  }

  @include desktop-large {
    --breakpoint: var(--breakpoint-desktop-large);
    --grid-debug-color: #fc0;
    --grid-max-width: 2560px;
    --card-grid-columns: 7;
  }
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))', $with-spacing: false) {
  display: grid;
  grid-gap: if($with-spacing, var(--grid-spacing), 0) var(--grid-gutter);
  grid-template-columns: string.unquote($columns);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
  padding-left: calc(var(--grid-gutter) + var(--grid-margin));
  padding-right: calc(var(--grid-gutter) + var(--grid-margin));
  width: 100%;
}
