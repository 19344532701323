
.menu-bar-contents {
  display: flex;
  flex-direction: row;
  font-size: 0;
  pointer-events: none;
  position: relative;

  .menu-bar-contents__buttons,
  .menu-bar-contents__menu-button,
  .menu-bar-contents__close-menu-button,
  .logo {
    pointer-events: initial;
  }

  .menu-bar-contents__start {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    position: relative;
    z-index: 4;
  }

  .logo--regular,
  .logo--horizontal-100years,
  .logo--only-100years {
    height: 2.75rem;
  }

  .logo--regular {
    width: 2.75rem;
  }

  .logo--vertical-100years {
    width: min(13.34vw, 10rem);
  }

  .logo--only-100years {
    position: fixed;
    z-index: -1;
  }

  .menu-bar-contents__middle {
    //
  }

  .menu-bar-contents__end {
    margin-left: auto;
    position: relative;
    z-index: 4;
  }

  .menu-bar-contents__buttons {
    pointer-events: none;

    .button {
      pointer-events: initial;
    }
  }

  .button {
    border-width: 2px;
  }

  .button + .button,
  .menu-bar-contents__buttons + .button {
    margin-left: var(--grid-gutter);
  }

  @include breakpoints(mobile-only, portrait-only) {
    .menu-bar-contents__buttons {
      --color-background: var(--color-black);
      --color-foreground: var(--color-white);

      background-color: var(--color-background);
      bottom: 0;
      color: var(--color-foreground);
      height: 2.75rem;
      left: 0;
      position: fixed;
      width: 100%;
    }

    .logo--vertical-100years {
      display: none;
    }
  }

  @include landscape {
    .menu-bar-contents__buttons {
      display: inline-block;
      margin-right: 1px;
    }

    .logo--horizontal-100years,
    .logo--regular,
    .logo--only-100years {
      display: none;
    }
  }
}
