
.modal {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: center;
  position: absolute;

  &:not(.modal--active) {
    display: none;
  }

  .modal__outer {
    inset: 0;
    overflow: auto;
    position: absolute;
  }

  .modal__grid {
    @include grid-container;

    height: 100vmin;
  }

  .modal__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
    position: relative;
  }

  .modal__button-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 999;
  }

  @include portrait {
    .modal__wrapper {
      grid-column: column 4 / span calc(var(--grid-columns) - 6);
    }
  }

  @include landscape {
    .modal__wrapper {
      grid-column: column 11 / span calc(var(--grid-columns) - 20);
    }
  }
}
