@use 'sass:string';

.card-grid {
  margin: var(--block-margin) 0;
  position: relative;

  .card-grid__grid {
    @include grid-container;
  }

  .card-grid__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 1fr;

    .card {
      flex: 1 1 33.33%;
      justify-self: center;
    }
  }

  @include portrait {
    .card-grid__cards {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include landscape {
    .card-grid__cards {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include notebook {
    .card-grid__cards {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @include desktop {
    .card-grid__cards {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  @include desktop-large {
    .card-grid__cards {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
