@use '../../../assets/stylesheets/utilities/mixins/_clearfix.scss';

@mixin cookie-bar-layout {
  position: relative;

  .cookie-bar__grid {
    @include grid-container;
  }

  .cookie-bar__bar-wrapper,
  .cookie-bar__categories {
    grid-column: column 1 / span var(--grid-columns);
  }

  .cookie-bar__bar,
  .cookie-bar__details {
    bottom: 0;
    height: auto;
    left: 0;
    padding: 1rem 0;
    position: fixed;
    width: 100%;
  }

  .cookie-bar__bar {
    z-index: 9998;
  }

  .cookie-bar__details {
    z-index: 9999;

    .cookie-bar__grid {
      padding-top: 1rem;
    }
  }

  .cookie-bar__bar-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .cookie-bar__text-container,
  .cookie-bar__buttons {
    transform: translate3d(0, 0, 0); // iOS 12 text visibility bug
  }

  .cookie-bar__buttons {
    @include clearfix.apply;
  }

  .cookie-bar__text-container {
    padding-right: 1rem;
    width: 100%;

    .cookie-bar__text-span,
    .link {
      margin-right: 1rem;
    }
  }

  .cookie-bar__categories {
    @include clearfix.apply;

    display: block;
  }

  .cookie-bar__category {
    display: flex;
    flex-direction: column;
    float: left;
    padding: 0 1rem 1rem;
    width: 100%;
  }

  @include mobile-only {
    .link,
    .button {
      margin-bottom: .5rem;
    }

    .cookie-bar__buttons {
      margin-top: 1rem;
    }
  }

  @include mobile-and-portrait-only {
    .cookie-bar__bar-wrapper {
      flex-direction: column;
    }

    .cookie-bar__details {
      border-top-width: 0;
      height: 100%;
      left: 0;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  @include portrait {
    .cookie-bar__buttons,
    .cookie-bar__details-buttons {
      white-space: nowrap;
    }

    .cookie-bar__category {
      width: 50%;
    }
  }
}

@mixin cookie-bar-styling {
  .cookie-bar__bar,
  .cookie-bar__details {
    --color-background: var(--color-black);
    --color-foreground: var(--color-white);

    background-color: var(--color-foreground);
    color: var(--color-background);
  }

  .cookie-bar__title {
    @include typography-heading-4;
  }

  .cookie-bar__text,
  .cookie-bar__category-text,
  .cookie-bar__text .link {
    @include typography-caption;
  }

  .cookie-bar__category-text {
    cursor: pointer;
    display: inline-block;
    max-width: 32em;
    user-select: none;
  }

  .cookie-bar__text {
    .cookie-bar__text-span {
      display: block;
      margin-bottom: 1rem;
    }

    .link {
      .link__span {
        text-decoration: underline;
      }

      &:hover,
      &:focus-visible {
        .link__span {
          text-decoration: none;
        }
      }
    }
  }

  .cookie-bar__text-container {
    display: flex;

    .cookie-bar__text {
      max-width: 50em;
    }
  }

  .link {
    display: inline;
  }

  .option-field {
    .option-field__icon {
      background: var(--color-foreground);
      color: var(--color-background);
    }
  }
}

@mixin cookie-bar-display-logic {
  .cookie-bar__bar,
  .cookie-bar__details {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 3rem, 0);
    transition: opacity var(--easing-change), transform var(--easing-change);
  }

  .cookie-bar__bar:target,
  .cookie-bar__bar--visible,
  .cookie-bar__details:target,
  .cookie-bar__details--visible {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
}

.cookie-bar {
  @include cookie-bar-layout;
  @include cookie-bar-styling;
  @include cookie-bar-display-logic;
}
