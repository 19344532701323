
.question-ball {
  aspect-ratio: 1/1;
  background-color: var(--color-background);
  border: 2px solid var(--color-background);
  border-radius: 100%;
  color: var(--color-foreground);
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  position: relative;
  transform: none;
  transform-origin: 50% 50%;
  transition: transform var(--easing-change);
  width: 100%;

  &[href] {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      transform: scale(1.05);
    }

    &:focus-visible {
      outline: 2px solid var(--color-black);
      outline-offset: 2px;
    }
  }

  .question-ball__wrapper {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    inset: 15%;
    justify-content: center;
    overflow: visible;
    position: absolute;
    text-align: center;
  }

  .question-ball__title {
    @include typography-heading-3;

    margin-bottom: 1.3rem;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .question-ball__text {
    margin-bottom: 1.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .button {
    flex: 0 0 auto;
    pointer-events: none;
  }

  html.konami-code &:not(.question-ball--submit) {
    animation: balloonbounce 2s infinite;
    overflow: visible;

    &::before,
    &::after {
      background-color: var(--color-background);
      content: '';
      display: block;
      position: absolute;
      transform-origin: 50% 50%;
      z-index: -1;
    }

    &::before {
      bottom: 5%;
      height: 1rem;
      left: 75%;
      transform: translate(-50%, 50%) rotate(61deg);
      width: 1rem;
    }

    &::after {
      border-radius: 50%;
      bottom: 2%;
      height: 2rem;
      left: 77%;
      transform: translate(-50%, 50%) rotate(61deg);
      width: 1rem;
    }

    @keyframes balloonbounce {
      0% {
        transform: translateY(0);
      }

      33% {
        transform: translateY(2%);
      }

      66% {
        transform: translateY(-2%);
      }

      100% {
        transform: translateY(0);
      }
    }

    &:nth-child(1n+1) {
      animation-delay: -250ms;
      animation-duration: 6s;
    }

    &:nth-child(2n+1) {
      animation-delay: 115ms;
      animation-duration: 3.5s;
    }

    &:nth-child(3n+1) {
      animation-delay: -419ms;
      animation-duration: 5s;
    }

    &:nth-child(4n+1) {
      animation-delay: 299ms;
      animation-duration: 7s;
    }

    &:nth-child(5n+1) {
      animation-delay: 50ms;
      animation-duration: 4.25s;
    }

    &:hover,
    &:focus-visible {
      animation-play-state: paused;
    }
  }
}

.question-ball--regular {
  @include style-theme-colors;

  background-color: var(--color-background);
  border-color: var(--color-background);
  color: var(--color-foreground);

  .question-ball__title {
    @include typography-heading-3;
  }
}

.question-ball--submit {
  --color-background: transparent;
  --color-foreground: currentcolor;

  background-color: transparent;
  border-color: currentcolor;
  color: currentcolor;

  .question-ball__title {
    @include typography-heading-2;
  }
}
