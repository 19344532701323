
.toggle {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  position: relative;

  &:focus-visible {
    outline: 2px solid currentcolor;
    outline-offset: 2px;
  }

  .toggle__label-off,
  .toggle__label-on {
    @include typography-heading-6;
  }

  .toggle__slider-container {
    align-items: center;
    border: 2px solid var(--color-black);
    display: flex;
    height: 1.375rem;
    justify-content: flex-start;
    padding: .25rem;
    position: relative;
    transition: justify-content .35s ease-in-out;
    width: 2.5rem;
  }

  .toggle__slider-ball {
    background-color: var(--color-black);
    border-radius: 100%;
    height: .875rem;
    position: absolute;
    width: .875rem;
  }

  &[aria-checked='true'] {
    .toggle__slider-container {
      justify-content: flex-end;
    }
  }
}
