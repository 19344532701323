
.picture {
  display: block;
  position: relative;
  user-select: none;

  .picture__image {
    height: 100%;
    opacity: 0;
    width: 100%;
  }
}

.picture--loaded {
  .picture__image {
    opacity: 1;
  }
}

.picture--background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  ~ * {
    position: relative;
    z-index: 1;
  }
}
