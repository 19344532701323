
.link {
  color: inherit;
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color var(--easing-change);
  vertical-align: baseline;

  .link__span {
    display: inline-block;
    text-decoration: underline;
    text-decoration-thickness: from-font;
    vertical-align: middle;
  }

  .link__icon {
    display: inline-block;
    height: 1em;
    position: relative;
    vertical-align: middle;
    width: 1em;
  }

  .link__icon + .link__span,
  .link__span + .link__icon {
    margin-left: .25em;
  }

  &:focus-visible {
    outline: 2px solid var(--color-black);
    outline-offset: 2px;
  }

  &:hover,
  &:focus-visible {
    .link__span {
      text-decoration: none;
    }
  }
}

.link--regular {
  //
}

.link--inverted {
  .link__span {
    text-decoration: none;
  }

  &:hover,
  &:focus-visible {
    .link__span {
      text-decoration: underline;
    }
  }
}
