
.logo {
  color: inherit;
  display: inline-block;
  height: auto;
  line-height: 0;
  position: relative;
  text-decoration: none;
  transition: color var(--easing-change);
  user-select: none;
  vertical-align: top;
  width: 100%;
}

a.logo:not(:disabled) {
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid var(--color-black);
    outline-offset: 2px;
  }
}

.logo--regular {
  aspect-ratio: 1 / 1;
}

.logo--vertical-100years {
  aspect-ratio: 274 / 343;
}

.logo--horizontal-100years {
  aspect-ratio: 235 / 47;
}

.logo--only-100years {
  aspect-ratio: 274 / 69;
}
