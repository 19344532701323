@use '../../../assets/stylesheets/utilities/mixins/adaptive-margin';
@use '../../../assets/stylesheets/utilities/mixins/adaptive-padding';

.form-fieldset {
  @include adaptive-margin.apply(4rem, 4rem);

  position: relative;

  > p {
    @include typography-paragraph-large;

    margin-top: .5rem;
  }

  .form-fieldset__legend {
    @include typography-heading-3;

    display: block;
    width: 100%;
  }
}
