
.grid-ball {
  --color-background: var(--color-white);
  --color-foreground: var(--color-black);
  --ball-font-size: 3vw;

  @include style-theme-colors;

  aspect-ratio: 1;
  background-color: var(--color-background);
  border: 2px solid var(--color-background);
  border-radius: 50%;
  clip-path: circle(50%);
  color: var(--color-foreground);
  cursor: pointer;
  font-size: var(--ball-font-size);
  font-weight: bold;
  grid-column: span 4;
  grid-row: span 4;
  margin: .25rem;
  overflow: hidden;
  position: relative;
  transition: margin var(--easing-change);

  &[aria-hidden='true'],
  &.hidden {
    display: none;
  }

  &:focus-visible,
  &:hover {
    margin: 0;
  }

  &:focus-visible {
    outline: 2px solid var(--color-black);
    outline-offset: 2px;
  }

  .button {
    font: inherit;

    .button__span {
      + .button__icon {
        margin-left: .45em;
        margin-top: -.1em;
      }
    }
  }

  .grid-ball__content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //transition: opacity var(--easing-change);
  }

  .grid-ball__content {
    position: relative;
  }

  .grid-ball__question {
    //@include typography-heading-6;

    display: block;
    line-height: 1.1;
  }

  .grid-ball__question-author {
    //@include typography-heading-6;

    display: none;
    line-height: 1.25;
  }

  .grid-ball__answer-link {
    display: none;
    font: inherit;
    line-height: 1.25;
    margin-top: calc(var(--ball-font-size) * 1);
    pointer-events: none;
  }

  &.size--medium {
    grid-column: span 8;
    grid-row: span 8;
    margin: .25rem;
    padding: 7%;

    .grid-ball__question {
      //@include typography-heading-3;
      font-size: calc(var(--ball-font-size) * 1.25);
    }
  }

  &.size--large,
  &.is-expanded {
    grid-column: span 16;
    grid-row: span 16;
    margin: .25rem;
    padding: 13%;

    .grid-ball__question {
      //@include typography-heading-2;

      font-size: calc(var(--ball-font-size) * 2.25);
    }

    .grid-ball__question-author {
      display: block;
      font-size: calc(var(--ball-font-size) * 1.35);
      font-weight: bold;
      margin-top: .5rem;
    }

    .grid-ball__answer-link {
      display: inline-flex;
      font-size: calc(var(--ball-font-size) * 1.35);
      font-weight: bold;
      pointer-events: initial;
      position: relative;
      z-index: 1;
    }
  }

  &.is-static {
    --color-background: transparent;
    --color-foreground: var(--color-black);

    background-color: var(--color-background);
    border-color: var(--color-foreground);
    color: var(--color-foreground);
  }

  @include landscape {
    --ball-font-size: 1.5vw;

    .grid-ball__answer-link {
      margin-top: .5em;
    }

    &.size--medium {
      margin: .5rem;
    }

    &.size--large,
    &.is-expanded {
      margin: 1rem;
      padding: 3rem;
    }

    &.is-static {
      border-width: 2px;
    }
  }

  @include notebook {
    --ball-font-size: 1vw;

    grid-column: span 2;
    grid-row: span 2;

    &.size--medium {
      grid-column: span 5;
      grid-row: span 5;
    }

    &.size--large,
    &.is-expanded {
      grid-column: span 10;
      grid-row: span 10;
      padding: 4rem;
    }
  }
}
