.form {
  position: relative;
  width: 100%;

  .form__wrapper {
    width: 100%;
  }

  .form__title {
    @include typography-heading-2;
  }

  .richtext {
    margin: 1rem 0 2rem;
  }

  .form__error-text {
    @include typography-paragraph-large;

    background: transparent;
    border: 1px solid var(--color-error);
    color: var(--color-error);
    margin: 1rem 0;
    padding: .5rem .75rem;
  }
}
