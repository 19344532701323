
.article-photo {
  margin: calc(var(--block-margin) * 1.5) 0;
  position: relative;

  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }
}

.article-photo--regular {
  @include landscape {
    .article-photo__wrapper {
      grid-column: column 5 / span calc(var(--grid-columns) - 8);
    }
  }
}

.article-photo--small {
  @include landscape {
    .article-photo__wrapper {
      grid-column: column 9 / span calc(var(--grid-columns) - 16);
    }
  }
}
