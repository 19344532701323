@use '../../../assets/stylesheets/utilities/mixins/_adaptive-margin.scss';

@mixin table-and-hr-for-static-templates {
  table {
    border: none;
    border-collapse: collapse;
    width: 100%;
  }

  td {
    padding-bottom: .5rem;
  }

  hr {
    appearance: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid currentcolor;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  @include landscape {
    td {
      &:not(:first-child) {
        padding-left: .5rem;
      }

      &:not(:last-child) {
        padding-right: .5rem;
      }
    }
  }

  @include mobile-and-portrait-only {
    thead {
      display: none;
    }

    table,
    tbody,
    tfoot,
    tr,
    td {
      display: block;
    }
  }
}

.richtext {
  position: relative;
}

.richtext--regular {
  @include table-and-hr-for-static-templates;

  h2 {
    @include typography-heading-2;
    @include adaptive-margin.apply(2rem, -.6rem);
  }

  h3 {
    @include typography-heading-3;
    @include adaptive-margin.apply(2rem, -.75rem);
  }

  h4 {
    @include typography-heading-4;
    @include adaptive-margin.apply(2rem, -.85rem);
  }

  h5 {
    @include typography-heading-5;
    @include adaptive-margin.apply(2rem, -.9rem);
  }

  h6 {
    @include typography-heading-6;
    @include adaptive-margin.apply(2rem, -.95rem);
  }

  p,
  ul,
  ol {
    @include adaptive-margin.apply(1rem, 1rem);
  }

  li {
    @include adaptive-margin.apply(.5rem, .5rem);
  }

  p,
  li {
    @include typography-paragraph-large;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        @include style-anchor-plain($inverted: false);

        color: inherit;

        &:focus-visible {
          outline: 2px solid var(--color-black);
          outline-offset: 2px;
        }
      }
    }
  }

  ul {
    @include style-list-base;
    @include style-list-unordered;
  }

  ol {
    @include style-list-base;
    @include style-list-ordered;
  }
}

.richtext--simple {
  p {
    @include adaptive-margin.apply(1rem, 1rem);
    @include typography-paragraph-large;
  }
}

.richtext--caption {
  margin: .5rem 0;

  p {
    @include adaptive-margin.apply(.5rem, .5rem);
    @include typography-caption;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        @include style-anchor-plain($inverted: false);

        color: inherit;
      }
    }
  }
}

.richtext--introduction {
  p {
    @include adaptive-margin.apply(1rem, 1rem);
    @include typography-introduction;

    a {
      &,
      &:link,
      &:active,
      &:visited {
        @include style-anchor-plain($inverted: false);

        color: inherit;
      }
    }
  }
}
