// Base (for vertical rhythm, font scaling etc.)

:root {
  --base-font-size: 16px;
  --base-font-family: #{$campton-font-stack};
  --base-font-weight: normal;
  --base-line-height: 1.4em;
}

// To calculate rems, for mobile: value / 16px, for desktop: value / 18px
// To calculate line height, divide value by font size

@mixin typography-paragraph {
  font-size: var(--base-font-size);
  font-weight: normal;
  letter-spacing: initial;
  line-height: var(--base-line-height);
}

@mixin typography-heading-1 {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: initial;
  line-height: 110%;

  @include landscape {
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: -.0625rem;
    line-height: 105%;
  }
}

@mixin typography-heading-2 {
  font-size: 1.625rem;
  font-weight: bold;
  letter-spacing: .0187rem;
  line-height: 110%;

  @include landscape {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: .0187rem;
    line-height: 110%;
  }
}

@mixin typography-heading-3 {
  font-size: 1.375rem;
  font-weight: bold;
  letter-spacing: initial;
  line-height: 130%;

  @include landscape {
    font-size: 1.75rem;
    font-weight: bold;
    letter-spacing: -.0052rem;
    line-height: 120%;
  }
}

@mixin typography-heading-4 {
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: -.0034rem;
  line-height: 130%;

  @include landscape {
    font-size: 1.312rem;
    font-weight: bold;
    letter-spacing: -.0187rem;
    line-height: 145%;
  }
}

@mixin typography-heading-5 {
  font-size: 1.0625rem;
  font-weight: bold;
  letter-spacing: initial;
  line-height: 130%;

  @include landscape {
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: initial;
    line-height: 145%;
  }
}

@mixin typography-heading-6 {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: initial;
  line-height: 130%;

  @include landscape {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: initial;
    line-height: 130%;
  }
}

@mixin typography-paragraph-large {
  font-size: 1.06rem;
  font-weight: normal;
  letter-spacing: initial;
  line-height: 135%;

  @include landscape {
    font-size: 1.25rem;
    font-weight: normal;
    letter-spacing: initial;
    line-height: 135%;
  }
}

@mixin typography-introduction {
  font-size: 1.375rem;
  font-weight: normal;
  letter-spacing: initial;
  line-height: 115%;

  @include landscape {
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: -.0312rem;
    line-height: 115%;
  }
}

@mixin typography-caption {
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: initial;
  line-height: 140%;
}

@mixin typography-button {
  font-size: var(--base-font-size);
  font-weight: bold;
  letter-spacing: initial;
  line-height: 1em;
}

@mixin typography-field-label {
  font-size: var(--base-font-size);
  font-weight: bold;
  letter-spacing: initial;
  line-height: var(--base-line-height);
}

@mixin typography-field-input {
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: initial;
  line-height: 145%;
}

@mixin typography-sponsor-logo {
  font-size: .85rem;
  font-weight: bold;
  letter-spacing: initial;
  line-height: 130%;
}
