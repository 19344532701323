@use '../mixins/visually-hidden';

[hidden] {
  display: none;
}

[hidden='screen'] {
  @include visually-hidden.apply;
}

sup,
sub {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  vertical-align: baseline;
}

sup {
  margin-top: -.4em;
  top: -.4em;
}

sub {
  bottom: -.2em;
  margin-bottom: -.2em;
}
