
.skip-to-main {
  height: 0;
  overflow: visible;
  text-align: center;
  width: 100%;

  .button {
    float: left;
    left: -200vw;
    position: absolute;
    top: 20px;

    &:focus-visible,
    &:active {
      float: none;
      left: auto;
      position: relative;
      z-index: 9999;
    }
  }
}
