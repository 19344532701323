
.article-cookies {
  margin: var(--block-margin) 0;
  position: relative;

  .article-cookies__grid {
    @include grid-container;
  }

  .article-cookies__wrapper {
    grid-column: column 2 / span calc(var(--grid-columns) - 2);
  }

  .article-cookies__title {
    @include typography-heading-2;
  }

  .article-cookies__text {
    @include typography-paragraph-large;

    margin-block-end: 2rem;
    margin-block-start: .5rem;
  }

  @include mobile-only {
    .button {
      margin-bottom: .5rem;
    }
  }

  @include landscape {
    .article-cookies__wrapper {
      grid-column: column 7 / span calc(var(--grid-columns) - 12);
    }
  }
}
