@use 'sass:map';

// Flexible webfont base file
// NOTE: When using this file as a base, rename all $*-font variables.

// Variable font settings
$campton-font-name: 'CamptonWebfont';
$campton-font-cleanname: 'campton';
$campton-font-path: '../fonts/' + $campton-font-cleanname + '/';
$campton-font-stack: $campton-font-name, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$campton-font-feature-settings: 'kern' 1, 'calt' 1, 'liga' 1, 'frac' 0, 'dlig' 0;

// Weight names and their real values
// Defined here, since these names can vary per font family. Foundries get up the the weirdest things sometimes.

// Reference:
// - 100: thin, hairline
// - 200 extra light, ultra light
// - 300 light
// - 400 normal, regular, book
// - 500 medium
// - 600 semibold, demibold
// - 700 bold
// - 800 extrabold, ultrabold
// - 900 black, heavy

// Reminder: Font style is not supposed to be selected by name (ie: 'black-italic'),
// this is done automatically by setting font-style italic. However, it's still in the
// list in order for the @font-face to work
$campton-font-weights: (
  book: (weight: normal, style: normal),
  medium: (weight: bold, style: normal),
);

// Automatically map weights
//@mixin campton-declarations {
@each $weight, $properties in $campton-font-weights {
  $font-style: map.get($properties, style);
  $font-weight: map.get($properties, weight);

  @font-face {
    font-display: swap;
    font-family: $campton-font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url($campton-font-path + $campton-font-cleanname + '-' + $weight + '.woff2') format('woff2');
  }
}

//}

// Font mixin whereby you can use the real font weights as displayed in Sketch, such as 'book' or 'black'.
@mixin font-campton($font-weight: null) {
  // Set the basics
  font-family: $campton-font-stack;
  font-feature-settings: $campton-font-feature-settings;
  font-size-adjust: initial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;

  @if $font-weight {
    font-weight: get-campton-weight($font-weight);
  }
}

// Given the weight name, get the real weight value. For example: 'black' returns 900.
@function get-campton-weight($font-weight) {
  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if not $font-weight or $font-weight == 'normal' or $font-weight == 'roman' {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if not (map.get($campton-font-weights, $font-weight)) {
    @error 'Variant "' + $font-weight + '" not found in "' + $campton-font-name + '" definition.';
  }

  $mapped-weight: map.get($campton-font-weights, $font-weight);
  $mapped-value-weight: map.get($mapped-weight, 'weight');

  @if $mapped-value-weight {
    $font-weight: $mapped-value-weight;
  }

  @return $font-weight;
}
