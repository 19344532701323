
.description-list {
  margin: .5rem 0;
  position: relative;

  .description-list__list {
    @include typography-paragraph;

    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .description-list__term {
    color: var(--color-grey);
    flex: 0 0 auto;
  }

  .description-list__description,
  .description-list__disclosure {
    flex: 0 0 100%;
    margin-left: 0;
  }

  .description-list__disclosure-button {
    appearance: none;
    color: inherit;
    display: inline-flex;
    flex-direction: row;
    font: inherit;
    margin: 0;
    padding: 0 2rem 0 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: from-font;
    text-transform: inherit;
    transition: color var(--easing-change), background-color var(--easing-change);
    white-space: nowrap;
    width: auto;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }

    &[aria-expanded='true'] {
      .description-list__disclosure-icon--expand {
        display: none;
      }
    }

    &:not([aria-expanded='true']) {
      .description-list__disclosure-icon--collapse {
        display: none;
      }
    }
  }

  .description-list__disclosure-button-span {
    display: block;
    flex: 0 1 100%;
  }

  .description-list__disclosure-icon {
    display: block;
    height: 1.5rem;
    position: absolute;
    right: .5rem;
    transform: none;
    transform-origin: 50% 50%;
    vertical-align: top;
    width: 1.5rem;
  }

  .description-list__disclosure-content-container {
    &[aria-hidden='true'] {
      display: none;
    }
  }

  .description-list__disclosure-content {
    padding: .5rem 0;
    position: relative;
  }

  @include portrait {
    .description-list__list {
      flex-direction: row;
    }

    .description-list__term {
      flex: 0 0 8ch;
    }

    .description-list__description,
    .description-list__disclosure {
      flex: 0 0 calc(100% - 8ch);
      margin-left: 1rem;
    }
  }
}
